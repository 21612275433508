import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';


import { BsFillTelephoneFill } from "react-icons/bs";
import { AiTwotoneMail } from "react-icons/ai";
import { LiaMapSolid } from "react-icons/lia";
import { TbWorldWww } from "react-icons/tb";


import VCard from "vcard-creator";



import './Module.css'

//-------------------------------------------Display--------------------------------------------------
// This function fetches data from backend

export default function Display (){

//setRecords is the function the save the data recieved from API call
    const [records, setRecords] = useState({
        //name of employee
        name: "",
        //position or post of the employee
        position: "",
        //Name of Company where the concerened employee is working
        company: "",
        //Phone Number of the Employee
        phone: "",
        //URL to website for the employee
        website: "",
        //Employees Address
        address: "",
        //Employees Email
        email:"" ,
        //FileID of the image stored in Google Drive
        image:{}
    });

    const params = useParams();

    useEffect(() => {
        // getRecords function fetches data from API
        async function getRecords() {
          const response = await fetch(`https://server-id.onrender.com/record/${params.id.toString()}`);
      
          if (!response.ok) {
            const message = `An error occurred: ${response.statusText}`;
            window.alert(message);
            return;
          }
      
          const records = await response.json();
          setRecords(records);
          console.log(records);

          const image_url = window.URL.createObjectURL(new Blob(records.image.buffer,{type:'image/png'}));

        }
        

        getRecords();
  
        
        return;
      }, [records.length, params.id]);

    
    //----------------------------------------------------------------------------
    //Following are all the click functions
    const handleCall = () => {
        window.location.assign('tel:'+records.phone);
    }

    const handleMail = () => {
        window.location.assign('mailto:'+records.email);
    }

    const handleMap = () => {
        window.location.assign("https://www.google.com/maps/search/"+records.address);
    }

    const handleWebsite = () => {
        window.location.assign(records.website);
    }

    const handleVCard = () => {
        let vCard = new VCard();
        vCard
            .addName(records.name)
            .addAddress(records.address)
            .addEmail(records.email)
            .addCompany(records.company)
            .addJobtitle(records.position)
            .addPhoneNumber(records.phone, "Work")
            .addURL(records.website)

        console.log(vCard.toString());

        const blob = new Blob([ vCard.toString() ], {type: "text;charset=utf-8"});
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        let nm = records.name.replaceAll(' ',"");
        link.download = nm+".vcf";
        link.href = url;
        link.click();


    }
    //--------------------------------------------------------------------------------------------

    //--------------------------------------------------------------------------------------------
    //returning the html code
    return(


      <Container  rounded className='bg-info-subtle p-3'>
      <div className=' d-flex flex-column mx-auto col-12 col-sm-10 my-3 '> 
        <div className='d-flex flex-nowrap overflow-hidden top-block rounded col-12' >
          <Image src={`data:image/jpeg;base64,${records.image}`} className='col-4'></Image>
          <div className='d-flex flex-column w-100 justify-content-center px-3 bg-info col-8'>
            <h1 className='text-secondary textsmall'>{records.name}</h1>
            <h6 className='text-secondary textsmall'>{records.position}</h6>
            <h6 className='text-secondary textsmaller'>{records.company}</h6>
          </div>
        </div>
        <Accordion className='col-12 py-3 w-100'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header><BsFillTelephoneFill className='mx-2'/>Phone</Accordion.Header>
            <Accordion.Body className='d-flex flex-column'>
              <div className='w-100 p-3'>
                <a onClick={handleCall} href={"tel:+91"+records.phone}>
                  +91{records.phone}
                </a>
              
              </div>
              
              <Button className='w-100'  variant='secondary' onClick={handleCall}>Call {records.name}</Button>  
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className='col-12 py-3 w-100'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header><AiTwotoneMail className='mx-2' />E-Mail</Accordion.Header>
            <Accordion.Body className='d-flex flex-column'>
              <div className='w-100 p-3'>
                <a onClick={handleMail} href={"mailto:"+records.email}>
                  {records.email}
                </a>
              
              </div>
              
              <Button className='w-100'  variant='secondary' onClick={handleMail}>Mail to {records.name}</Button>  
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className='col-12 py-3 w-100'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header><LiaMapSolid className='mx-2' />Location</Accordion.Header>
            <Accordion.Body className='d-flex flex-column'>
              <div className='w-100 p-3' >
              {records.address} 
              </div>
              
              <Button className='w-100'  variant='secondary' onClick={handleMap}>Open in Google Maps</Button>  
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className='col-12 py-3 w-100'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header><TbWorldWww className='mx-2' />Website</Accordion.Header>
            <Accordion.Body className='d-flex flex-column'>
              <div className='w-100 p-3'>
                <a onClick={handleWebsite} href={records.website}>
                  {records.website}
                </a>
              </div>
              
              <Button className='w-100'  variant='secondary' onClick={handleWebsite}>Open in Browser</Button>  
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <button className="col-12 py-3 w-100 rounded btn btn-info my-3 text-white" onClick={handleVCard}>
          <strong>Download Vcard</strong>
        </button>

        

      </div>
      </Container>

    );
    //--------------------------------------------------------------------------------

}




