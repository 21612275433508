import React from 'react'
import { BrowserRouter as  Router, Routes, Route } from "react-router-dom";
import Display from './component/Display';
import Sample from './component/Sample';

// Sends data to index.js and routes to /id
export default function App() {
  return (
    <Router>
        <div >
            <Routes>
                <Route exact path='/' element={<Sample />}></Route>
                <Route exact path='/:id' element={<Display />}></Route>
            </Routes>
        </div>
    </Router>
        

  )
}
