import React from 'react';
import "bootstrap/dist/css/bootstrap.css";
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Navbar from 'react-bootstrap/Navbar';
import Accordion from 'react-bootstrap/Accordion';


import {BsFillTelephoneFill} from 'react-icons/bs';
import { AiTwotoneMail } from "react-icons/ai";
import { LiaMapSolid } from "react-icons/lia";
import { TbWorldWww } from "react-icons/tb";

import './Module.css'
import 'holderjs'
function Sample() {
  return (
   
    <Container  rounded className='bg-info-subtle p-3'>
      <div className=' d-flex flex-column mx-auto col-12 col-sm-10 my-3 '> 
        <div className='d-flex flex-nowrap overflow-hidden top-block rounded col-12' >
          <Image src={'holder.js/170x200'} className='col-4'></Image>
          <div className='d-flex flex-column w-100 justify-content-center px-5 bg-info col-8'>
            <h1 className='text-secondary'>Name</h1>
            <h6 className='text-secondary'>Position</h6>
            <h6 className='text-secondary'>Company</h6>
          </div>
        </div>
        <Accordion className='col-12 py-3 w-100'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header><BsFillTelephoneFill className='mx-2'/>Phone</Accordion.Header>
            <Accordion.Body className='d-flex flex-column'>
              <div className='w-100 p-3'>
               +91999999999 
              </div>
              
              <Button className='w-100'  variant='secondary'>Call Name</Button>  
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className='col-12 py-3 w-100'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header><AiTwotoneMail className='mx-2' />E-Mail</Accordion.Header>
            <Accordion.Body className='d-flex flex-column'>
              <div className='w-100 p-3'>
               +91999999999 
              </div>
              
              <Button className='w-100'  variant='secondary'>Mail to Name</Button>  
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className='col-12 py-3 w-100'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header><LiaMapSolid className='mx-2' />Location</Accordion.Header>
            <Accordion.Body className='d-flex flex-column'>
              <div className='w-100 p-3'>
               +91999999999 
              </div>
              
              <Button className='w-100'  variant='secondary'>Open in Google Maps</Button>  
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className='col-12 py-3 w-100'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header><TbWorldWww className='mx-2' />Website</Accordion.Header>
            <Accordion.Body className='d-flex flex-column'>
              <div className='w-100 p-3'>
               +91999999999 
              </div>
              
              <Button className='w-100'  variant='secondary'>Open in Browser</Button>  
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        

      </div>
    </Container>
   
  )
}

export default Sample